import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import TotalVisitMonthly from "./TotalVisitMonthly"
import TotalPlaceMonthly from "./TotalPlaceMonthly"
import VehicleByStatus from "./VehicleByStatus"
import VehicleByBrand from "./VehicleByBrand"

function DashboardContainer() {
  const user = parsedUser()
  const { t } = useTranslation()
  const [generalInfo, setGeneralInfo] = useState(null)
  const [summaryByStage, setSummaryByStage] = useState([])
  const [summaryByStatus, setSummaryByStatus] = useState([])
  const [vehicleByGPSModel, setVehicleByGPSModel] = useState([])

  const [selectedUser, setSelectedUser] = useState(null)
  const [users, setUsers] = useState([])
  const requestUsers = async () => {
    let requestAPI = await API.getAction(
      "users/List?OrganizationId=" + user.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      setUsers(requestAPI.data.response)
    }
  }

  const bindDataRemote = async () => {
    try {
      let record = await API.getAction(
        "dashboard/general?organizationId=" + user.OrganizationId
      )
      if (record.data.status === "ok") {
        setGeneralInfo(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }
  const bindDataByStageRemote = async () => {
    try {
      let record = await API.getAction(
        "place/summaryByStage?organizationId=" +
          user.OrganizationId +
          ("&assignedTo=" + (selectedUser ?? ""))
      )
      if (record.data.status === "ok") {
        setSummaryByStage(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }

  const bindDataByStatusRemote = async () => {
    try {
      let record = await API.getAction(
        "place/summaryByStatus?organizationId=" +
          user.OrganizationId +
          ("&assignedTo=" + (selectedUser ?? ""))
      )
      if (record.data.status === "ok") {
        setSummaryByStatus(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }

  const bindDataByVehicleByGPSModelRemote = async () => {
    try {
      let record = await API.getAction(
        "agl/VehicleByGPSModel?organizationId=" + user.OrganizationId
      )
      if (record.data.status === "ok") {
        setVehicleByGPSModel(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }

  useEffect(() => {
    bindDataRemote()
    bindDataByStageRemote()
    bindDataByStatusRemote()
    bindDataByVehicleByGPSModelRemote()
    requestUsers()
  }, [])

  useEffect(() => {
    bindDataRemote()
    bindDataByStageRemote()
    bindDataByStatusRemote()
  }, [selectedUser])
  return (
    <Container>
      <Row className="my-4">
        <Col xs={12}>
          <h2>{t("General info")}</h2>
          <hr />
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>{generalInfo && generalInfo.totalPlaces}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-map-location me-2"></i>
              {t("Lugares")}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                {generalInfo && generalInfo.totalVehicles}
              </Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-car me-2"></i>
              {t("Vehiculos")}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>{generalInfo && generalInfo.totalUsers}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-user me-2"></i>
              {t("Agentes")}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>{generalInfo && generalInfo.totalVisits}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-calendar me-2"></i>
              {t("Visitas")}
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} className="mt-4">
          <h4>{t("Agent Info.")}</h4>
          <hr />
        </Col>
        <Col xs={12}>
          <Card className="mb-4">
            <Card.Header className="p-1 px-4">
              <Card.Title>{t("Filter")}</Card.Title>
            </Card.Header>
            <Card.Body className="bg-light p-1 px-4">
              <Row>
                <Col xs={12} md={4} xl={3}>
                  <label>{t("Agent")}</label>
                  <br />
                  <select
                    className="form-control"
                    value={selectedUser}
                    onChange={e => setSelectedUser(e.target.value)}
                  >
                    <option value="">-</option>
                    {users &&
                      users.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.firstName + " " + (item.lastName ?? "")}
                          </option>
                        )
                      })}
                  </select>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* SUMMARY BY STAGE */}
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>{t("Resumen por etapa")}</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                    {summaryByStage.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <i className={item.icon + " me-2"}></i>
                          {item.name}
                        </td>
                        <td className="text-end">{item.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        {/* SUMMARY BY Status */}
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>{t("Resumen Lugares por estatus")}</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                    {summaryByStatus.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <i
                            className={item.icon + " me-2"}
                            style={{ color: item.color }}
                          ></i>
                          {item.name}
                        </td>
                        <td className="text-end">{item.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-4">
        <Col xs={12} md={6}>
          <TotalVisitMonthly agent={selectedUser} />
        </Col>
        <Col xs={12} md={6}>
          <TotalPlaceMonthly agent={selectedUser} />
        </Col>
        <Col xs={12} className="mt-4">
          <h4>{t("Vehiculos")}</h4>
          <hr />
        </Col>

        {/* VEHICLE BY GPS MODEL   */}
        <Col xs={12} md={6} lg={8}>
          <VehicleByStatus />
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>{t("Vehiculos por Modelo GPS")}</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                    {vehicleByGPSModel.map((item, index) => (
                      <tr key={index}>
                        <td>{item.brand}</td>
                        <td>{item.model}</td>
                        <td className="text-end">{item.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={6} lg={4}>
          <VehicleByBrand />
        </Col>
      </Row>
    </Container>
  )
}

export default DashboardContainer
