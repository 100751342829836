import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { useTranslation } from "react-i18next"

function VehicleByBrand() {
  const { t } = useTranslation()
  const user = parsedUser()
  const [records, setRecords] = useState([])

  const bindDataRemote = async () => {
    try {
      let record = await API.getAction(
        "agl/VehicleByBrand?organizationId=" + user.OrganizationId
      )
      if (record.data.status === "ok") {
        setRecords(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [])
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title>{t("Vehiculos por Marca")}</Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              {records.map((item, index) => (
                <tr key={index}>
                  <td>{item.brand}</td>
                  <td className="text-end">{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  )
}

export default VehicleByBrand
