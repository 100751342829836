import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"

const PlaceVisitComponent = (props: any) => {
  const [t] = useTranslation("global")
  const user = parsedUser()
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={props.show}
      toggleCRUD={props.toggle}
      title={t("Visits Management") ?? ""}
      getRecordsPath={`PlaceVisit/list?OrganizationId=${user.OrganizationId}`}
      addEditRecordPath={`PlaceVisit/AddEdit`}
      deleteRecordPath="PlaceVisit/Delete"
      fieldsList={[
        {
          name: "createdBy",
          title: t("Created By"),
        },
        {
          name: "created",
          title: t("created"),
        },
        {
          name: "placeName",
          title: t("Place"),
        },
        {
          name: "note",
          title: t("note"),
        },
        {
          name: "longitude",
          title: t("longitude"),
        },
        {
          name: "latitude",
          title: t("latitude"),
        },
      ]}
      fields={[
        {
          title: t("Id") ?? "",
          name: "id",
          htmlType: "text",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "icon",
          htmlType: "text",
          dbType: "string",
          title: t("icon") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
        {
          name: "color",
          htmlType: "color",
          dbType: "string",
          title: t("color") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
      ]}
    />
  )
}

export default PlaceVisitComponent
