import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { useTranslation } from "react-i18next"

function VehicleByStatus() {
  const { t } = useTranslation()
  const user = parsedUser()
  const [vehicleByStatus, setVehicleByStatus] = useState([])
  const vehicleStatus = status => {
    return status === 4
      ? "Movimiento"
      : status === 1
      ? "Parado"
      : status === 3
      ? "Ralenti/Estacionado"
      : "Desconocido " + status
  }
  const vehicleStatusIcon = status => {
    return status === 4
      ? "fa-solid fa-truck-moving text-success"
      : status === 1
      ? "fa-solid fa-hand text-danger"
      : status === 3
      ? "fa-solid fa-truck text-warning"
      : "fa-solid fa-question  "
  }
  const bindDataVehicleByStatusRemote = async () => {
    try {
      let record = await API.getAction(
        "agl/VehicleByStatus?organizationId=" + user.OrganizationId
      )
      if (record.data.status === "ok") {
        setVehicleByStatus(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }
  useEffect(() => {
    bindDataVehicleByStatusRemote()
  }, [])
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title>{t("Vehiculos por Estatus")}</Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="table-responsive">
          <table className="table table-striped">
            <tbody>
              {vehicleByStatus.map((item, index) => (
                <tr key={index}>
                  <td>
                    <i className={vehicleStatusIcon(item.status) + " me-2"}></i>
                    {vehicleStatus(item.status)}
                  </td>
                  <td className="text-end">{item.count}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  )
}

export default VehicleByStatus
