import React, { useEffect, useState } from "react"
//import { Card, Button } from "react-bootstrap"

//import Profile1 from "../../assets/img/team/profile-picture-1.jpg"
//import ProfileCover from "../../assets/img/profile-cover.jpg"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { userAvatar } from "../../common/helpers"
import AddEditOrganization from "./AddEditOrganization"
import { useHistory } from "react-router-dom"
//Translation
import { useTranslation } from "react-i18next"
import { Routes } from "../../routes"

const OrganizationBox = props => {
  const history = useHistory()
  const [state, setState] = useState({
    Organization: null,
    isLoading: false,
    showEdit: false,
  })

  const theUser = parsedUser()

  const { t } = useTranslation("global")

  const request = async () => {
    setState({
      isLoading: !state.isLoading,
      ...state,
    })
    let requestAPI = await API.getAction(
      "Organization/Details?Id=" + theUser.OrganizationId
    )

    if (requestAPI.data.status === "ok") {
      setState({
        isLoading: !state.isLoading,
        Organization: requestAPI.data.result,
      })
    }
  }
  const toggleAddEdit = reload => {
    setState({
      ...state,
      showEdit: !state.showEdit,
    })

    if (reload) {
      request()
    }
  }
  useEffect(() => {
    request()
  }, [])

  return (
    <>
      {state.showEdit ? (
        <AddEditOrganization
          show={state.showEdit}
          toggle={toggleAddEdit}
          model={state.Organization}
        />
      ) : null}
      <div className="row mb-4">
        {/* <div className="col-auto">
          <img
            loading="lazy"
            src={state.Organization && userAvatar(state.Organization.name, 160)}
            className="user-avatar  rounded-circle img-fluid"
            alt=""
          />
        </div> */}
        <div className="col-auto">
          <h3
            className="p-0 m-0 clickable px-2"
            onClick={() => {
              if (props.readOnly) {
                history.push(Routes.SelectOrganization.path)
              }
            }}
          >
            {state.Organization && state.Organization.name}
            <i className="fa fa-chevron-down ms-2"></i>
          </h3>
        </div>
        {!props.readOnly ? (
          <div className="col-auto ml-auto">
            <button
              className="btn btn-primary btn-sm "
              onClick={() => toggleAddEdit()}
            >
              {t("edit")}
            </button>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default OrganizationBox
