import React, { useEffect, useState } from "react"
import { parsedUser } from "../../common/GetCurrentUser"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js"
import { Bar, Line } from "react-chartjs-2"
import API from "../../common/API"
import moment from "moment"
import { FormatMoney } from "../../common/helpers"
import { Col, Row } from "react-bootstrap"

const TotalVisitMonthly = props => {
  const [state, setState] = useState(null)
  const [records, setRecords] = useState([])
  const user = parsedUser()
  const bindDataRemote = async () => {
    let query = `organizationId=${user.OrganizationId}`
    if (props.agent) {
      query += `&agentId=${props.agent}`
    }
    const request = await API.getAction(`dashboard/VisitsByMonth?${query}`)

    console.log(request)

    setRecords(request.data.response)
  }

  useEffect(() => {
    if (records) {
      const labels = records.map(item => moment(item.month).format("MMM, YYYY"))
      setState({
        //  [],
        labels,
        datasets: [
          {
            label: "Mes",
            data: records.map(item => item.count),
            backgroundColor: "red",
          },
        ],
      })
    }
  }, [records])

  useEffect(() => {
    bindDataRemote()
  }, [props.agent])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Total Purchased",
      },
    },
  }
  useEffect(() => {
    bindDataRemote()
  }, [])

  if (!state || !records) {
    return <></>
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <Row>
            <Col>
              <span className="display-6 d-inline text-uppercase">
                Visitas por mes
              </span>
            </Col>
          </Row>
        </div>
        <div className="card-body">
          {/* <Bar options={options} data={records} /> */}
          <Line options={options} data={state} />
        </div>
        {/* <div className="card-footer">
          <h4>
            Total: {FormatMoney(records.reduce((a, b) => a + b.total, 0))} gl
          </h4>
        </div> */}
      </div>
    </>
  )
}

export default TotalVisitMonthly
